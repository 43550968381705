import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/datamigrators.github.io/datamigrators.github.io/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This page describes Data Migrators’ Code of Conduct policy.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Overview</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Purpose</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Scope</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Policy Compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Exceptions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Non-compliance</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Related Documents</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Definitions and Terms</AnchorLink>
    </AnchorLinks>
    <h2>{`Overview`}</h2>
    <p>{`Our Employee Code of Conduct company policy outlines our expectations regarding employees’ behaviour towards their colleagues, supervisors and overall organisation.`}</p>
    <h2>{`Purpose`}</h2>
    <p>{`We promote freedom of expression and open communication. But we expect all employees to follow our code of conduct. They should avoid offending, participating in serious disputes and disrupting our workplace. We also expect them to foster a well-organised, respectful and collaborative environment.`}</p>
    <h2>{`Scope`}</h2>
    <p>{`This policy applies to all our employees regardless of employment agreement or rank.`}</p>
    <h2>{`Policy`}</h2>
    <p>{`What are the components of an Employee Code of Conduct Policy?
Company employees are bound by their contract to follow our Employee Code of Conduct while performing their duties. We outline the components of our Code of Conduct below:`}</p>
    <h3>{`Compliance with law`}</h3>
    <p>{`All employees must protect our company’s legality. They should comply with all environmental, safety and fair dealing laws. We expect employees to be ethical and responsible when dealing with our company’s finances, products, partnerships and public image.`}</p>
    <h3>{`Respect in the workplace`}</h3>
    <p>{`All employees should respect their colleagues. We won’t allow any kind of discriminatory behaviour, harassment or victimisation. Employees should conform with our equal opportunity policy in all aspects of their work, from recruitment and performance evaluation to interpersonal relations.`}</p>
    <h3>{`Protection of Company Property`}</h3>
    <p>{`All employees should treat our company’s property, whether material or intangible, with respect and care.`}</p>
    <h2>{`Policy Compliance`}</h2>
    <p>{`Employees:`}</p>
    <ul>
      <li parentName="ul">{`Shouldn’t misuse company equipment or use it frivolously.`}</li>
      <li parentName="ul">{`Should respect all kinds of incorporeal property. This includes trademarks, copyright and other property (information, reports etc.) Employees should use them only to complete their job duties.`}</li>
      <li parentName="ul">{`Should protect company facilities and other material property from damage and vandalism, whenever possible.`}</li>
    </ul>
    <h3>{`Professionalism`}</h3>
    <p>{`All employees must show integrity and professionalism in the workplace:`}</p>
    <h3>{`Personal appearance`}</h3>
    <p>{`All employees must follow our dress code and personal appearance guidelines.`}</p>
    <h3>{`Corruption`}</h3>
    <p>{`We discourage employees from accepting gifts from clients or partners. We prohibit briberies for the benefit of any external or internal party.`}</p>
    <h3>{`Job duties and authority`}</h3>
    <p>{`All employees should fufill their job duties with integrity and respect toward customers, stakeholders and the community. Supervisors and managers mustn’t abuse their authority. We expect them to delegate duties to their team members taking into account their competences and workload. Likewise, we expect team members to follow team leaders’ instructions and complete their duties with skill and in a timely manner.`}</p>
    <p>{`We encourage mentoring throughout our company. `}</p>
    <h3>{`Absenteeism and tardiness`}</h3>
    <p>{`Employees should follow their schedules. We can make exceptions for occasions that prevent employees from following standard working hours or days. But, generally, we expect employees to be punctual when coming to and leaving from work.`}</p>
    <h3>{`Conflict of interest`}</h3>
    <p>{`We expect employees to avoid any personal, financial or other interests that might hinder their capability or willingness to perform their job duties.`}</p>
    <h3>{`Collaboration`}</h3>
    <p>{`Employees should be friendly and collaborative. They should try not to disrupt the workplace or present obstacles to their colleagues’ work.`}</p>
    <h3>{`Communication`}</h3>
    <p>{`All employees must be open for communication with their colleagues, supervisors or team members.`}</p>
    <h3>{`Benefits`}</h3>
    <p>{`We expect employees to not abuse their employment benefits. This can refer to time off, insurance, facilities, subscriptions or other benefits our company offers.`}</p>
    <h3>{`Policies`}</h3>
    <p>{`All employees should read and follow our company policies. If they have any questions, they should ask their managers or Human Resources (HR) department.`}</p>
    <h2>{`Exceptions`}</h2>
    <p>{`None.`}</p>
    <h2>{`Non-compliance`}</h2>
    <p>{`Data Migrators may have to take disciplinary action against employees who repeatedly or intentionally fail to follow our code of conduct. Disciplinary actions will vary depending on the violation.`}</p>
    <p>{`Possible consequences include:`}</p>
    <ul>
      <li parentName="ul">{`Demotion`}</li>
      <li parentName="ul">{`Reprimand`}</li>
      <li parentName="ul">{`Suspension or termination for more serious offences`}</li>
      <li parentName="ul">{`Detraction of benefits for a definite or indefinite time`}</li>
      <li parentName="ul">{`Legal action in cases of corruption, theft, embezzlement, or other unlawful behaviour`}</li>
    </ul>
    <h2>{`Related Documents`}</h2>
    <p>{`None.`}</p>
    <h2>{`Definitions and Terms`}</h2>
    <p>{`None.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      